<template>
  <!-- 今日实收 -->
  <div class="today-container content-index">
    <div class="navTop">
      <el-page-header
        @back="$router.back()"
        content="实收列表"
      ></el-page-header>
    </div>
    <div class="formBox">
      <el-date-picker
        v-model="selectDate"
        type="daterange"
        size="medium"
        value-format="yyyy-MM-dd"
        @change="selectTimes"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        :picker-options="pickerOptions"
      ></el-date-picker>
      <el-select
        v-model="formData.payWay"
        clearable
        placeholder="付款方式"
        size="medium"
        style="margin: 0 10px;"
      >
        <el-option
          v-for="item in payList"
          :key="item.itemValue"
          :label="item.itemText"
          :value="item.itemValue"
        />
      </el-select>
      <el-input
        placeholder="请输入手机号/订单号"
        size="medium"
        v-model="formData.orderOrPhone"
        style="width: 260px; margin-right: 10px;"
        clearable
      ></el-input>
      <el-button type="primary" size="medium" @click="queryInfo">
        确定
      </el-button>
    </div>
    <div class="tableTitle">
      <p>实收金额: {{ totalPic }}元</p>
    </div>
    <el-table
      :data="tableData"
      style="width: 100%;"
      class="customer-table"
      :header-cell-style="{ color: '#363636', fontWeight: '500' }"
    >
      <el-table-column type="index" label="序号"> 
         <template slot-scope="scope">
              {{ (Number(formData.current) - 1) * 10+scope.$index + 1 }}
            </template>
      </el-table-column>
      <el-table-column prop="orderNumber" label="订单号" width="180px">
        <template slot-scope="scope">
          <span>{{ scope.row.orderNumber }}</span>
          <el-button
            type="text"
            class="copyBth"
            @click.stop="handleCopy(scope.row.orderNumber)"
          >
            复制
          </el-button>
        </template>
      </el-table-column>
      <el-table-column
        prop="userName"
        label="联系人"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column prop="userPhone" label="电话" width="150px">
      </el-table-column>
      <el-table-column prop="orderStatus" label="订单状态"></el-table-column>
      <el-table-column prop="clothesNum" label="衣物件数"></el-table-column>
      <el-table-column
        prop="orderTime"
        label="收衣时间"
        width="150px"
      ></el-table-column>
      <el-table-column
        prop="payTime"
        label="付款时间"
        width="150px"
      ></el-table-column>
      <el-table-column prop="payWay" label="付款方式"></el-table-column>
      <el-table-column
        prop="orderOriginalPrice"
        label="订单金额"
      ></el-table-column>
      <el-table-column
        prop="actuallyPaidPrice"
        label="实付金额"
      ></el-table-column>
      <el-table-column label="操作" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="getClothesOrderDetails(scope.row.orderNumber)"
          >
            查看详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="footer">
      <el-pagination
        background
        @current-change="handleCurrentChange"
        @size-change="handleSizeChange"
        layout="prev,pager,next,sizes"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="formData.size"
        :total="total"
      ></el-pagination>
    </div>
    <!-- 订单详情 -->
    <transition name="userDetails">
      <!-- v-if="dialogTableVisible" -->
      <order-detail
        v-if="dialogTableVisible"
        :orderDetailOption="orderDetailOption"
        @diaLogIsShow="dialogClose"
        v-loading="assemblyLoading"
      ></order-detail>
    </transition>
  </div>
</template>

<script>
import {
  getTodayNetReceipts,
  queryDictItemByDictName,
} from '@/api/todayData/index.js'
import { getClothesOrderDetails } from '@/api/clothes/order'
import OrderDetail from '@/components/OrderDetails'

export default {
  data() {
    return {
      loading: false,
      assemblyLoading: false,
      formData: {
        size: 10,
        current: 1,
        beginTime: '',
        endTime: '',
        payWay: '',
        orderOrPhone: '',
      },
      total: 0,
      selectDate: [
        this.$moment(new Date()).format('YYYY-MM-DD'),
        this.$moment(new Date()).format('YYYY-MM-DD'),
      ],
      payList: [],
      totalPic: 0,
      tableData: [],
      dialogTableVisible: false,
      orderDetailOption: {
        clothesList: [],
      },
      pickerOptions: {
        shortcuts: [
          {
            text: '昨天',
            onClick(picker) {
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24)
              picker.$emit('pick', [start, start])
            },
          },
          {
            text: '今天',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
              picker.$emit('pick', [start, end])
            },
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date()
              const start = new Date()
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
              picker.$emit('pick', [start, end])
            },
          },
        ],
        onPick: ({ maxDate, minDate }) => {
          this.selectDate = minDate.getTime()
          if (maxDate) {
            this.selectDate = ''
          }
        },
        disabledDate: (time) => {
          if (this.selectDate !== '') {
            const one = 90 * 24 * 3600 * 1000
            const minTime = this.selectDate - one
            const maxTime = this.selectDate + one
            return (
              time.getTime() < minTime ||
              time.getTime() > maxTime ||
              time.getTime() > Date.now() - 8.64e6
            )
          }
        },
      },
    }
  },
  components: {
    OrderDetail,
  },
  created() {
    this.getPayType()
    this.selectTimes()
    this.getPageInfo()
  },
  methods: {
    queryInfo() {
      this.formData.current = 1
      this.getPageInfo()
    },
    selectTimes() {
      this.formData.beginTime = this.selectDate ? this.selectDate[0] : ''
      this.formData.endTime = this.selectDate ? this.selectDate[1] : ''
    },
    async getPayType() {
      const { data: res } = await queryDictItemByDictName('支付方式')
      if (res.code != 0) return this.$message.error('获取支付方式失败')
      ;(this.payList = []),
        res.data.forEach((item) => {
          if (item.itemText !== '先洗后付' && item.itemText !== '余额支付') {
            this.payList.push(item)
          }
        })
    },
    async getPageInfo() {
      this.loading = true
      const { data: res } = await getTodayNetReceipts(this.formData)
      console.log('页面数据', res)
      if (res.code != 0) return this.$message.error(res.msg)
      this.totalPic = res.data.actuallyPaidPrice
      this.tableData = res.data.orderList.map((item) => {
        item.userPhone =  item.userPhone.slice(0, 3) + "****" + item.userPhone.slice(7);
        return item
      })
      this.total = res.data.total
      this.loading = false
    },
    handleCurrentChange(val) {
      this.formData.current = val
      this.getPageInfo()
    },
    handleSizeChange(val) {
      this.formData.size = val
      this.getPageInfo()
    },
    //获取订单详情
    getClothesOrderDetails(orderNumber) {
      this.dialogTableVisible = true
      this.assemblyLoading = true
      this.orderNumber = orderNumber
      getClothesOrderDetails(orderNumber).then((response) => {
        this.assemblyLoading = false
        this.orderDetailOption = response.data.data
      })
    },
    dialogClose(isShow) {
      this.dialogTableVisible = isShow
    },
    // 处理复制
    // handleCopy(value) {
    //   let _this = this
    //   _this.$copyText(value).then(
    //     function (e) {
    //       _this.$message.success('复制成功')
    //     },
    //     function (e) {
    //       _this.$message.error('复制失败')
    //     },
    //   )
    // },
  },
}
</script>

<style scoped lang="scss">

::v-deep .el-table__row > td {
  /* 去除表格线 */
  border: none;
}

::v-deep .el-table th.is-leaf {
  /* 去除上边框 */
  border: none;
}

::v-deep.el-table::before {
  /* 去除下边框 */
  height: 0;
}

::v-deep .cell {
  text-align: center;
}

.today-container {
  padding: 20px;
  overflow: auto;

  .navTop {
    p {
      width: 60px;
      height: 30px;
      margin: 0;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      line-height: 30px;
      border-radius: 5px;

      &:hover {
        background-color: #ecf5ff;
        // color: #fff;
      }
    }
  }

  .formBox {
    margin-top: 20px;
  }

  .tableTitle {
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    font-weight: 700;
  }

  .content {
    max-height: 800px;
    overflow-y: auto;

    ul {
      display: flex;
      padding: 10px 0;
      background-color: #f5f7fa;
      margin: 0;

      li {
        flex: 1;
        list-style: none;
        text-align: center;
      }

      li:nth-child(1) {
        flex: 0.5;
      }
    }

    ol {
      display: flex;
      padding: 10px 0;
      margin: 0;

      li {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        list-style: none;
        text-align: center;
        font-size: 14px;

        .copyBth {
          margin-left: 10px;
          padding: 0;
        }
      }

      li:nth-child(1) {
        flex: 0.5;
      }
    }
  }

  .footer {
    margin-top: 20px;
    text-align: right;
  }
}

//动画过程
.userDetails-enter {
  animation: fullscreen-dialog-fade-in 0.4s ease;
}

.userDetails-leave {
  animation: fullscreen-dialog-fade-out 0.4s ease forwards;
}

.userDetails-enter-active {
  animation: fullscreen-dialog-fade-in 0.4s ease;
}

.userDetails-leave-active {
  animation: fullscreen-dialog-fade-out 0.4s ease forwards;
}

@keyframes fullscreen-dialog-fade-in {
  0% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
  100% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fullscreen-dialog-fade-out {
  0% {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  100% {
    transform: translate3d(100%, 0, 0);
    opacity: 0;
  }
}
</style>
